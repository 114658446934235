import React from 'react'


export const MoreIcon = ({ stroke }) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path stroke={stroke} d="M6.39997 7.41248C6.39997 8.05733 6.92342 8.58009 7.56913 8.58009C8.21483 8.58009 8.73828 8.05733 8.73828 7.41248C8.73828 6.76763 8.21483 6.24487 7.56913 6.24487C6.92342 6.24487 6.39997 6.76763 6.39997 7.41248Z" strokeWidth="0.700303" strokeLinecap="round" strokeLinejoin="round" />
      <path stroke={stroke} d="M11.0758 7.41248C11.0758 8.05733 11.5992 8.58009 12.2449 8.58009C12.8906 8.58009 13.4141 8.05733 13.4141 7.41248C13.4141 6.76763 12.8906 6.24487 12.2449 6.24487C11.5992 6.24487 11.0758 6.76763 11.0758 7.41248Z" strokeWidth="0.700303" strokeLinecap="round" strokeLinejoin="round" />
      <path stroke={stroke} d="M1.72419 7.41248C1.72419 8.05733 2.24764 8.58009 2.89335 8.58009C3.53905 8.58009 4.0625 8.05733 4.0625 7.41248C4.0625 6.76763 3.53905 6.24487 2.89335 6.24487C2.24764 6.24487 1.72419 6.76763 1.72419 7.41248Z" strokeWidth="0.700303" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}