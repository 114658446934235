import React from 'react';
import { Avatar } from '../../Avatar';
import { Row } from '../../common';
import { PreviewType } from '../../../utils/touchpoints';
import { getInitialsHandler } from '../Touchpoint';
import { isSafari } from '../../../utils/device';

interface AvatarRowProps {
  previews: PreviewType[]
}
export const AvatarRow: React.FC<AvatarRowProps> = React.memo(({ previews }) => {
  return (
    <Row className='touchpoint_avatar_row'>
      {previews.map((p, index) => {
        return (<Avatar
          textPosY={isSafari() ?  0.6 : 0.5}
          initials={getInitialsHandler(p)}
          key={index}
          src={p.avatar?.url}
          className='touchpoint_small_avatars'
          style={{
            marginLeft: index > 0 ? -5 : 0,
            width: 16,
            height: 16
          }}
        />)
      })}
    </Row>
  )
})
