import React, { useCallback, useEffect, useState } from 'react';
import Cookie from 'js-cookie'

import './reset.css';

import './App.less';
import {
  Scene_1,
  Scene_1_2,
  Scene_2,
  Scene_3,
  Scene_4,
  Scene_5,
  Scene_6,
} from './scenes'
import { getTextWithSiteTypeHelper } from './utils/getTextWithSiteType'

import { Header, Footer } from './components';
import { UserContext } from './userContext';
import { apiClient } from './utils/api';
import { useTranslation } from 'react-i18next';

const isBusinessUrl = (pathname: string) => {
  return pathname === '/business' || pathname === '/business/'
}

const App = () => {
  const [chatUnreadCounter, setChatUnreadCounter] = useState(0)
  const [profile, setProfile] = useState({})
  const [referal, setReferal] = useState({})
  const [isAuthenticated, setAuthenticated] = useState(false)
  const isBusinessSiteType = isBusinessUrl(window.location.pathname)
  const { t } = useTranslation()
  const getTextWithSiteType = getTextWithSiteTypeHelper(isBusinessSiteType, t)

  const fetchUser = async () => {
    const access = Cookie.get('access')
    try {
      if (access) {
        const responseJson = await (await apiClient.post(`/auth/check/`, {})).json()
        const dialogs = await apiClient.get(`/web/api/chats/updates/?page_size=${1}&page=${1}`)
        const {
          counters: {
            my_contacts,
            unprocessed_touchpoints
          }
        } = await dialogs.json()

        setChatUnreadCounter(my_contacts + unprocessed_touchpoints)
        setProfile(responseJson)
        setAuthenticated(true)
      } else {
        setAuthenticated(false)
      }
    } catch (e) {
      setAuthenticated(false)
    }
  }

  const checkReferral = async () => {
    try {
      const refFlag = '/r/'
      const refIdx = window.location.pathname.indexOf(refFlag)

      if (refIdx > -1) {
        const i = refIdx + refFlag.length
        const code = window.location.pathname.slice(i, i + 11)
        const profile = await (await apiClient.getNoAuth(`/web/api/profiles/referral/get-by-code/?code=${code}`)).json()
        setReferal({
          code,
          profile,
        })
      }
    } catch (e) {
      console.error(e)
    }
    fetchUser()
  }


  const logout = useCallback(() => {
    Cookie.remove('access')
    Cookie.remove('refresh')
    setAuthenticated(false)
    setProfile(false)
  }, [setAuthenticated, setProfile])
  const login = (e) => {
    e && e.preventDefault && e.preventDefault()
    if (referal && referal.code) {
      localStorage.setItem('invite_code', referal.code);
    }
    window.location.href = `/touchpoints`;
  }

  useEffect(() => {
    checkReferral()
  }, [])

  return (
    <UserContext.Provider
      value={{
        chatUnreadCounter,
        profile,
        referal,
        isAuthenticated,
        login,
        logout,
        isBusinessSiteType,
        getTextWithSiteType
      }}
    >
      <div className="relative">
        <Header />
        <Scene_1 />
        <Scene_1_2 />
        <Scene_2 />
        <Scene_4 />
        <Scene_5 />
        <Scene_3 />
        <Scene_6 />
        <Footer />
      </div>
    </UserContext.Provider>
  )
};

export default App;
