import React, { useContext } from 'react';
import styled from 'styled-components';

import { useMediaQuery } from 'react-responsive';

import devices from '../devices';

import Person1Img from '../../public/nikita.png';
import Person2Img from '../../public/eldar.png';
import Person1Business from '../../public/paul.png';
import Person2Business from '../../public/pedro.png';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../userContext';

const Card = styled.div`
    display: flex;
    width: 515px;
    position: relative;
    border-radius: 12px;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(119.72deg, #FFFFFF 14.87%, #F9FBFE 107.34%);
    box-shadow: 0px 2px 8px rgba(10, 107, 255, 0.1), 0px 16px 40px rgba(10, 107, 255, 0.03), 0px 36px 86px rgba(111, 151, 214, 0.06);
    flex-direction: row;

    @media ${devices.laptop} {
        width: 100%;
        flex-direction: column;
    }
`

const Person = styled.img`
    width: 180px;
    height: 316px;
    object-fit: cover;

    @media ${devices.laptop} {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin: 0 auto;
        margin-top: 32px;
    }
`

const RoundPerson = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    flex-shrink: 0;
    object-fit: cover;
    margin: 36px 0 0 37px;

    @media ${devices.laptop} {
        margin: 32px auto 0 auto;
    }
`

const HeaderTitle = styled.h2`
    font-size: 34px;
    font-weight: 600;
    line-height: 47px;
    max-width: 800px;
    text-align: center;
    margin-top: 100px;

    @media ${devices.laptop} {
        font-size: 28px;
        line-height: 42px;
        margin-top: 80px;
    }
`
const Title = styled.div`
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
    margin-top: 32px;
    margin-left: 20px;

    @media ${devices.laptop} {
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        margin-left: 0;
    }
`

const Content = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 160%;
    color: #545D6C;
    padding: 21px 36px 36px 20px;

    @media ${devices.laptop} {
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        padding-bottom: 32px;
    }
`

const Container = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;

    @media ${devices.laptop} {
        svg {
            width: 45px;
            height: 35px;
        }
    }
`

export default () => {
    const isMobile = useMediaQuery({ query: devices.laptop })
    const [t] = useTranslation('translations');
    const { getTextWithSiteType, isBusinessSiteType } = useContext(UserContext)
    return (
        <>
            <div className="container container-mobile h-middle">
                <HeaderTitle>{t('scenes.scene-5.title')}</HeaderTitle>
            </div>
            <div className="container container-mobile" style={{ justifyContent: 'space-around' }}>
                <div style={{ marginTop: isMobile ? 24 : 60 }}>
                    <Card style={{ height: !isBusinessSiteType ? '100%' : 'auto' }}>
                        {isBusinessSiteType
                            ? <Person src={isBusinessSiteType ? Person1Business : Person1Img} />
                            : <RoundPerson src={isBusinessSiteType ? Person1Business : Person1Img} />
                        }
                        <div>
                            <Title>
                                {getTextWithSiteType('scenes.scene-5.dude-1.name')}
                                {isBusinessSiteType ? <br /> : <span>, </span>}
                                {getTextWithSiteType('scenes.scene-5.dude-1.employee')}
                            </Title>
                            <Content dangerouslySetInnerHTML={{ __html: getTextWithSiteType('scenes.scene-5.dude-1.quote') }} />
                        </div>
                    </Card>
                </div>
                <div style={{ marginTop: isMobile ? 20 : !isBusinessSiteType ? 60 : 152 }}>
                    <Card style={{ height: !isBusinessSiteType ? '100%' : 'auto' }}>
                        {isBusinessSiteType
                            ? <Person src={isBusinessSiteType ? Person2Business : Person2Img} />
                            : <RoundPerson src={isBusinessSiteType ? Person2Business : Person2Img} />
                        }
                        <div>
                            <Title>
                                {getTextWithSiteType('scenes.scene-5.dude-2.name')}
                                {isBusinessSiteType ? <br /> : <span>, </span>}
                                {getTextWithSiteType('scenes.scene-5.dude-2.employee')}
                            </Title>
                            <Content dangerouslySetInnerHTML={{ __html: getTextWithSiteType('scenes.scene-5.dude-2.quote') }} />
                        </div>
                    </Card>
                </div>
            </div>
        </>
    )
}