import styled from 'styled-components';
import React from 'react'

export const DownloadBtn = styled.a`
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: black;
    padding: 12px 16px;
    border-radius: 8px;
    flex:1;
`
export const AppIcon = styled.img`
    display: inline-block;
    margin-right: 8px;
    position: relative;
    width: 14px;
    height: 16px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`