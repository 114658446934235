import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const options = {
  fallbackLng: 'en',
  ns: ['translations'],
  defaultNS: 'translations',
  detection: {},
  interpolation: {},
};

// for browser use http backend to load translations and browser lng detector
if (process && !process.release) {
  i18n
    .use(Backend)
    .use(initReactI18next);
}

// initialize if not already initialized
if (!i18n.isInitialized) {
  i18n.init(options);
}

export default i18n;