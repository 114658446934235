import styled from "styled-components";
import { GoalCard, IGoalCardProps } from "../components/GoalCard";
import React from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 140px auto;
  width: 675px;
  @media (max-width: 675px) {
    width: 100%;
    margin: 80px auto;
  }
`;

const Title = styled.h1`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 140%;
  @media (max-width: 675px) {
    font-size: 20px;
    line-height: 150%;
  }
`;
const Description = styled.h1`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  text-align: center;
  color: #545d6c;

  margin-top: 16px;

  @media (max-width: 675px) {
    font-size: 15px;
    line-height: 170%;
    padding: 0 32px;
  }
`;

const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 12px;
  row-gap: 12px;
  margin-top: 60px;
  @media (max-width: 675px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding:0 32px 20px 32px;
    margin-top: 40px;
  }
}
`;

const cards: IGoalCardProps[] = [
  {
    emoji: "📝",
    title: "Get Recommended to New Customers",
    backgroundColor: "#C6F5FF",
    url: "https://intch.org/blog/how-to-get-new-customers-on-intch/?utm_source=main_land&utm_medium=JTBD&utm_campaign=get_customers",
  },
  {
    emoji: "👋",
    title: "Connect with Like-Minded People",
    backgroundColor: "#FFDBF5",
    url: "https://futureofwork.intch.org/meetlikemindedpeople?utm_source=main_land&utm_medium=JTBD&utm_campaign=like_mind_people",
  },
  {
    emoji: "💸",
    title: "Raise Capital",
    backgroundColor: "#F0E9DE",
    url: "https://intch.org/blog/how-to-raise-capital-on-intch/?utm_source=main_land&utm_medium=JTBD&utm_campaign=raise_capital",
  },
  {
    emoji: "🚀",
    title: "Find Side Project",
    backgroundColor: "#E5E8FF",
    url: "https://futureofwork.intch.org/?utm_source=main_land&utm_medium=JTBD&utm_campaign=side_projects",
  },
  {
    emoji: "😎",
    title: "Reach Top Niche Experts",
    backgroundColor: "#FFEBBB",
    url: "https://intch.org/blog/how-to-meet-experts-on-intch/?utm_source=main_land&utm_medium=JTBD&utm_campaign=meet_experts",
  },
  {
    emoji: "⚡",
    title: "Find Jobs on a Hidden Market",
    backgroundColor: "#C4FFAF",
    url: "https://futureofwork.intch.org/findajob?utm_source=main_land&utm_medium=JTBD&utm_campaign=find_job",
  },
  {
    emoji: "💼",
    title: "Find a Perfect Co-Founder",
    backgroundColor: "#E5F5D1",
    url: "https://intch.org/blog/how-to-find-a-co-founder-in-2023/?utm_source=main_land&utm_medium=JTBD&utm_campaign=find_co_founder",
  },
  {
    emoji: "🙌",
    title: "Find a Trusted Mentor",
    backgroundColor: "#D3E9FE",
    url: "https://intch.org/blog/how-to-find-a-mentor-on-intch/?utm_source=main_land&utm_medium=JTBD&utm_campaign=find_mentor",
  },
  {
    emoji: "🤝",
    title: "Hire Top Global Professionals",
    backgroundColor: "#FBD8D8",
    url: "https://futureofwork.intch.org/employers?utm_source=main_land&utm_medium=JTBD&utm_campaign=hiring",
  },
];

export const Scene_1_2 = () => {
  const [t] = useTranslation();
  return (
    <Container>
      <Title>{t("scenes.scene-1_2.title", "How Intch can help you")}</Title>
      <Description>
        {t(
          "scenes.scene-1_2.description",
          "Intch helped 110k+ people all over the globe solve their tacks with the power of networking."
        )}
      </Description>

      <CardsWrapper>
        {cards.map((card) => {
          return (
            <GoalCard
              key={card.title}
              url={card.url}
              backgroundColor={card.backgroundColor}
              emoji={card.emoji}
              title={card.title}
            />
          );
        })}
      </CardsWrapper>
    </Container>
  );
};

export default Scene_1_2;
