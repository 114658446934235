import React from "react"

export const RightIcon = ({ fill }) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path fill={fill} d="M6.6456 10.5224C6.50886 10.6591 6.28715 10.6591 6.15041 10.5224C6.01367 10.3856 6.01367 10.1639 6.15041 10.0272L6.6456 10.5224ZM9.19922 7.47358L9.44681 7.22598L9.69441 7.47358L9.44681 7.72117L9.19922 7.47358ZM6.15041 4.91996C6.01367 4.78322 6.01367 4.56151 6.15041 4.42477C6.28715 4.28803 6.50886 4.28803 6.6456 4.42477L6.15041 4.91996ZM6.15041 10.0272L8.95162 7.22598L9.44681 7.72117L6.6456 10.5224L6.15041 10.0272ZM8.95162 7.72117L6.15041 4.91996L6.6456 4.42477L9.44681 7.22598L8.95162 7.72117Z" />
      </g>
    </svg>
  )
}