import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import styled from "styled-components";

const Container = styled.span`
  &.fade-enter {
    opacity: 0;
    transform: translateX(-50%);
  }
  &.fade-enter-active {
    opacity: 1;
    transform: translateX(0%);
  }
  &.fade-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  &.fade-exit-active {
    opacity: 0;
    transform: translateX(50%);
  }
  &.fade-enter-active,
  &.fade-exit-active {
    transition: opacity 1000ms ease-in-out, transform 1000ms ease-in-out;
  }
`

export const AnimatedTitle = () => {
    const [t] = useTranslation('translations');
    const [currentTitle, setCurrentTitle] = useState(1)

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTitle((currentTitle) => currentTitle <= 3 ? currentTitle + 1 : 1)
        }, 5000)
        return () => clearInterval(interval)
    }, [])

    return (
        <>
            <SwitchTransition>
                <CSSTransition
                    key={currentTitle}
                    classNames="fade"
                    timeout={1000}
                >
                    <Container style={{ display: 'block', color: '#6987D7' }}>
                        {t(`scenes.scene-1.title-${currentTitle}.text`)}
                    </Container>
                </CSSTransition>
            </SwitchTransition>
            {t(`scenes.scene-1.subtitle`)}
            <SwitchTransition>
                <CSSTransition
                    key={currentTitle}
                    classNames="fade"
                    timeout={1000}
                >
                    <Container>
                        {' ' + t(`scenes.scene-1.title-${currentTitle}.emoji`)}
                    </Container>
                </CSSTransition>
            </SwitchTransition>
        </>
    )
}
