import React from "react";
import styled from "styled-components";
import Next from "../../public/next.svg";

export interface IGoalCardProps {
  title: string;
  emoji: string;
  backgroundColor: string;
  url: string;
}

const Link = styled.a`
  text-decoration: none;
  color: black;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 234px;
  width: 217px;
  border-radius: 12px;
  padding: 20px 20px 24px;
  justify-content: space-between;
  cursor: pointer;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-property: box-shadow;
  :hover {
    box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.1);
  }
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
`;

const EmojiText = styled.span`
  font-size: 26px;
`;

const Title = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
`;

const TextWrapper = styled.div`
  display: flex;
  flexdirection: row;
  align-items: flex-end;
`;

const RightIcon = styled.img`
  margin-left: 13px;
`;

export const GoalCard: React.FC<IGoalCardProps> = ({
  title,
  emoji,
  backgroundColor,
  url,
}) => {
  return (
    <Link target={"_blank"} href={url}>
      <Container style={{ backgroundColor }}>
        <Circle>
          <EmojiText>{emoji}</EmojiText>
        </Circle>
        <TextWrapper>
          <Title>{title}</Title>
          <RightIcon src={Next} />
        </TextWrapper>
      </Container>
    </Link>
  );
};
