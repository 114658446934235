import React from 'react';
import styled from 'styled-components';
import devices from '../devices';
import { useTranslation } from 'react-i18next';
import bg from '../../public/scene-2-bg.jpg';
import qrCode from '../../public/scene-6-qr-code.png';
import { AppIcon, DownloadBtn } from '../components';

const Container = styled.div`
    height: 613px;
    display: flex;
    background: url(${bg});
    background-size: cover;
    align-items: center;
    justify-content: center;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

`
const Title = styled.h2`
    font-size: 48px;
    line-height: 140%;
    font-weight: 600;
    margin-bottom: 40px;
    text-align: center;
    @media ${devices.laptop} {
        font-size: 28px;
        line-height: 42px;
    }
`


const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media ${devices.laptop} {
        flex-direction: column;
    }

`

const ButtonSpace = styled.div`
    width: 16px;
    height: 16px;
`

const DownloadBtnWrapper = styled.div`
    flex: 1;
    max-width:252px;
    @media ${devices.laptop} {
        max-width:100%;
    }
`

const Scan = styled.p`
    margin: 40px 0;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: #818FA4;
`

const QrCode = styled.img`
    align-self: center;
    width: 120px;
    height: 120px;
`

export default () => {
    // TODO(akim): i18n
    const [t] = useTranslation('translations');

    const iosIcon = require(`../../public/apple-logo-white.svg`)
    const googleIcon = require(`../../public/google-play-logo.svg`)

    return (
        <Container className="container-mobile">
            <Wrapper>
                <Title>{t('scenes.scene-6.title')}</Title>
                <ButtonsWrapper>
                    <DownloadBtnWrapper>
                        <DownloadBtn style={btnStyle} href={process.env.RAZZLE_APPLE_URL}>
                            <AppIcon src={iosIcon} style={{ top: -2 }} />{t('buttons.downloadiOS')}
                        </DownloadBtn>
                    </DownloadBtnWrapper>
                    <ButtonSpace />
                    <DownloadBtnWrapper>
                        <DownloadBtn style={btnStyle} href={process.env.RAZZLE_ANDROID_URL}>
                            <AppIcon src={googleIcon} />{t('buttons.downloadAndroid')}
                        </DownloadBtn>
                    </DownloadBtnWrapper>
                </ButtonsWrapper>
                <Scan>
                    {t('scenes.scene-6.scan')}
                </Scan>
                <QrCode src={qrCode} alt="app-intch-qrcode" />
            </Wrapper>
        </Container>
    )
}

const btnStyle = {
    flex: 1,
    marginBottom: 0,
}
