import React, { useContext } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { UserContext } from '../userContext';

import devices from '../devices';

import bg from '../../public/scene-2-bg.jpg';

const Background = styled.div`
    background: url(${bg});
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 1059px;

    @media ${devices.laptop} {
        height: auto;
    }
`

const YouTubeVid = styled.iframe`
    width: 568px;
    aspect-ratio: 72/45;
    margin-bottom: 60px;
    border-radius: 18px/20px;

    @media ${devices.laptop} {
        width: 100%;
        aspect-ratio: 351/198;
        margin-bottom: 30px;
        border-radius: 0;
    }
`

const Container = styled.div`
    max-width: 568px;

    @media ${devices.laptop} {
        padding: 0 33px 37px 33px;
        box-sizing: border-box;
    }
`

const Title = styled.h2`
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    text-align: center;

    @media ${devices.laptop} {
        font-size: 28px;
        line-height: 42px;
    }
`

const Content = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    margin-top: 40px;
    color: black;
    white-space: pre-wrap;

    @media ${devices.laptop} {
        margin-top: 20px;
        text-align: left;
    }
`

const List = styled.ol`
    list-style-type: decimal;
    margin-left: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    margin-top: 27px;

    @media ${devices.laptop} {
        text-align: left;
    }
`

const ListItem = styled.li``

export default () => {
    const {
        getTextWithSiteType,
        isBusinessSiteType,
    } = useContext(UserContext)
    const [t] = useTranslation();
    return (
        <Background>
            <YouTubeVid
                src="https://www.youtube.com/embed/SMB6lWGGVwc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            />
            <Container>
                <Title>{getTextWithSiteType('scenes.scene-2.title')}</Title>
                <Content>
                    <Trans
                        i18nKey={getTextWithSiteType('scenes.scene-2.content')}
                        components={{
                            bold: (<span style={{ fontWeight: '700' }} />),
                        }}
                    />
                </Content>
                {!isBusinessSiteType && <List>
                    <Trans
                        i18nKey={t('scenes.scene-2.list')}
                        components={{
                            bold: (<span style={{ fontWeight: '700' }} />),
                            li: (<ListItem />),
                        }}
                    />
                </List>}
            </Container>
        </Background>
    )
}
