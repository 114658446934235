import { apiClient } from './api';

type BackgroundType = {
  url: string
  name: string
  size: number
  media_hash: string
  url_valid_until: string
}

type ThemeType = {
  id: number
  fontColor: string
  background: BackgroundType,
  shadeColor: string
  categoryStyle: {
    fontColor: string
    backgroundColor: string
  },
  webBackground: BackgroundType
  mobileBackground: BackgroundType
  indicatorActiveColor: string
  indicatorInactiveColor: string
}

type ViewType = {
  general: number,
  discussion: number,
  extended_apply: number,
  extended_intro: number
}

export type PreviewType = {
  name: string
  first_name: string
  last_name: string
  avatar: {
    media_hash: string
    url: string
    name: string
    size: number
  },
  profile_url: string
}

export type IServTouchpoint = {
  id: number,
  config: {
    theme: ThemeType,
    title: string
  },
  background: {
    media_hash: string
    url: string
    name: string
    size: number
  },
  attachments: [],
  apply_url: string
  intro_url: string
  stats: {
    entries: {
      total_messages: number,
      count: number,
      preview: PreviewType[]
    },
    views: {
      web: ViewType,
      mobile: ViewType,
      total: number
    }
  },
  touchpoint_type: number
  category_id: number
  discussion_dialog_id: number
  version: number
  profile: {
    id: number
    first_name: string
    last_name: string
    avatar_url: string
    company: string
    job_title: string
  },
  category: {
    id: number,
    key: string
  }
}

export const getTouchpoints = async (isBusiness?: boolean) => {
  try {
    const url = isBusiness ? '/landing/requests?type=business' : "/landing/requests"
    const res = await apiClient.getNoAuth(url)
    const json = await res.json() as IServTouchpoint[]
    return json
  }
  catch (e) {
    throw e
  }
}